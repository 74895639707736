import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customized-order',
  templateUrl: './customized-order.component.html',
  styleUrls: ['./customized-order.component.scss']
})
export class CustomizedOrderComponent implements OnInit {
    public themeLogo: string = 'assets/images/icon/logo-13.png'; // Change Logo
  constructor() { }

  ngOnInit(): void {
  }

}
