<app-header-four [themeLogo]="themeLogo"></app-header-four>

<!-- Home slider start-->
<section class="p-0">
  <app-home-slider
    [sliders]="sliders" 
    [textClass]="'text-center'"
    [category]="'fashion'"
    [buttonText]="'shop now'"> 
  </app-home-slider>
</section>
<!-- Home slider End -->

<!-- collection banner start-->
<!-- <section class="pb-0">
  <div class="container">
    <div class="row partition2">
      <div class="col-md-6" *ngFor="let collection of collections">
        <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
          <div class="collection-banner p-right text-center">
            <img [src]="collection.image" class="img-fluid" alt="collection-banner">
            <div class="contain-banner">
              <div>
                <h4>{{collection.save}}</h4>
                <h2>{{collection.title}}</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section> -->
<!-- collection banner End -->

<!-- Product slider start-->
<div class="title1 section-t-space">
  <h4>special offer</h4>
  <h2 class="title-inner1">top collection</h2>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="product-para">
        <p class="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry, 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </p>
      </div>
    </div>
  </div>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:16">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-product-box-one 
                  [product]="product" 
                  [currency]="productService?.Currency"
                  [thumbnail]="true"
                  [cartModal]="true">
                </app-product-box-one>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Product slider End -->

<!-- Parallax banner start-->
<!-- <section class="p-0">
  <div class="full-banner parallax-banner1 parallax text-center p-left"
    [ngStyle]="{'background-image': 'url(assets/images/parallax/1.jpg)'}">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h2>2018</h2>
            <h3>fashion trends</h3>
            <h4>special offer</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- Parallax banner End -->

<!-- Tab product start-->
<!-- <div class="title1 section-t-space">
  <h4>exclusive products</h4>
  <h2 class="title-inner1">special products</h2>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
          <ngb-tab *ngFor="let collection of productCollections">
            <ng-template ngbTabTitle>{{collection}}</ng-template>
            <ng-template ngbTabContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one 
                      [product]="product" 
                      [currency]="productService?.Currency"
                      [thumbnail]="true"
                      [cartModal]="true">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</section> -->
<!-- Tab product End -->

<!-- services start-->
<!-- <div class="container">
  <section class="service border-section small-section">
    <app-services></app-services>
  </section>
</div> -->
<!-- services End -->

<!-- blog section start-->
<!-- <section class="blog p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-nm-blog [blogs]="blog"></app-nm-blog>
      </div>
    </div>
  </div>
</section> -->
<!-- blog section End -->

<!--  logo section start-->
<!-- <section class="section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-nm-logo [logos]="logo"></app-nm-logo>
      </div>
    </div>
  </div>
</section> -->
<!--  logo section End-->

<app-newsletter></app-newsletter>
<app-footer-one></app-footer-one>

