import { Component, OnInit, Input } from '@angular/core';
import { LogoSlider } from '../shared/data/slider';

@Component({
  selector: 'app-nm-logo',
  templateUrl: './nm-logo.component.html',
  styleUrls: ['./nm-logo.component.scss']
})
export class NMLogoComponent implements OnInit {
  
  @Input() logos: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public LogoSliderConfig: any = LogoSlider;

}
