import { Component, OnInit, Input } from '@angular/core';
import { BlogSlider } from '../shared/data/slider';

@Component({
  selector: 'app-nm-blog',
  templateUrl: './nm-blog.component.html',
  styleUrls: ['./nm-blog.component.scss']
})
export class NMBlogComponent implements OnInit {
  
  @Input() blogs: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  public BlogSliderConfig: any = BlogSlider;

}
